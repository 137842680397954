import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				KickIt Arena
			</title>
			<meta name={"description"} content={"Розташована в зручному місці, наша арена оснащена першокласними зручностями, які підвищать ваш футбольний досвід. Завдяки простому онлайн-бронюванню та гнучким часовим слотам, KickIt Arena - це ваше найкраще місце для всього, що стосується футболу."} />
			<meta property={"og:title"} content={"KickIt Arena"} />
			<meta property={"og:description"} content={"Розташована в зручному місці, наша арена оснащена першокласними зручностями, які підвищать ваш футбольний досвід. Завдяки простому онлайн-бронюванню та гнучким часовим слотам, KickIt Arena - це ваше найкраще місце для всього, що стосується футболу."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Contact>
		<Components.Nav />
		<Section padding="180px 0 180px 0" md-padding="100px 0 100px 0" quarkly-title="Content-12" background="linear-gradient(0deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0.65) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z) 50% 50% /cover repeat scroll padding-box">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				width="50%"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="white">
					Контактна інформація
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Ми працюємо щодня з 8:00 до 22:00, щоб забезпечити зручний графік для всіх наших клієнтів. Забронюйте поле або заплануйте тренування заздалегідь, щоб гарантувати собі місце на нашому стадіоні.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Contacts-3">
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="--headline2"
				color="--darkL1"
				text-align="left"
				padding="0px 0 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
			>
				Приєднуйтесь до нас сьогодні
			</Text>
			<Text
				margin="16px 40% 50px 0px"
				font="--base"
				lg-margin="0px 0 50px 0px"
				text-align="left"
				sm-margin="0px 0 35px 0px"
				color="--dark"
				lg-max-width="720px"
			>
				Щоб забронювати футбольне поле або дізнатися більше про наші послуги, зв'яжіться з нами за телефоном. Ми завжди готові допомогти вам з будь-якими питаннями та організувати ваші заходи на найвищому рівні. Наш стадіон знаходиться у зручному місці, що дозволяє легко дістатися до нас з будь-якої частини міста. Чекаємо на ваші дзвінки та заявки!
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
					width="50%"
				>
					<Text margin="0px 0px 15px 0px" font="--lead">
						Адреса
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						Monastyrskyi ostriv St, Dnipro,{" "}
						<br />
						Dnipropetrovsk Oblast, 49000
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
					width="50%"
				>
					<Text margin="0px 0px 15px 0px" font="--lead">
						Номер телефону
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						0567359050
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Nav>
			<Override slot="link4" />
		</Components.Nav>
		<Components.Contact>
			<Override slot="link1">
				0567359050
			</Override>
			<Override slot="link">
				Monastyrskyi ostriv St, Dnipro, Dnipropetrovsk Oblast, 49000
			</Override>
		</Components.Contact>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});